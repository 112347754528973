import React from 'react';

const useHideMundialeChatbot = () => {
  React.useEffect(() => {
    const chatbot = document.getElementById('blip-chat-container');

    if (chatbot) {
      chatbot.setAttribute('style', 'display: none !important');
    }

    return () => {
      if (chatbot) {
        chatbot.setAttribute('style', 'display: block !important');
      }
    };
  });
};

export default useHideMundialeChatbot;