import { useRef } from 'react';
import useLayoutEffect from './use-layout-effect';

const useInitialFocusRef = function () {
  let isFocusable = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  const ref = useRef();
  useLayoutEffect(() => {
    if (isFocusable && ref.current) {
      ref.current.focus();
    }
  }, [isFocusable, ref.current]);
  return ref;
};

export default useInitialFocusRef;