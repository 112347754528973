export const pushAddProductToCart = (courseName, courseId, coursePrice) => {
  if (courseName && courseId && coursePrice) {
    window.dataLayer.push({
      ecommerce: null
    });
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        items: [{
          item_name: courseName,
          price: coursePrice,
          quantity: 1,
          item_id: courseId,
          item_brand: 'Ampli',
          item_category: 'EAD'
        }]
      }
    });
  }
};
export const pushProductCheckout = (courseName, courseId, coursePrice) => {
  if (courseName && courseId && coursePrice) {
    window.dataLayer.push({
      ecommerce: null
    });
    window.dataLayer.push({
      event: 'checkout',
      ecommerce: {
        items: [{
          item_name: courseName,
          price: coursePrice,
          quantity: 1,
          item_id: courseId,
          item_brand: 'Ampli',
          item_category: 'EAD'
        }]
      }
    });
  }
};
export const pushProductPurchase = (courseName, courseId, price, transactionId) => {
  if (courseName && courseId && price && transactionId) {
    window.dataLayer.push({
      ecommerce: null
    });
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        items: [{
          item_name: courseName,
          revenue: price,
          quantity: 1,
          item_id: courseId,
          item_brand: 'Ampli',
          item_category: 'EAD'
        }],
        transaction_id: transactionId
      }
    });
  }
};