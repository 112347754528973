import { useState, useEffect } from 'react';
import { INGRESS_MODALITY, SPECIAL_FIELDS_TO_SHOW_BY_DEGREE_TYPE, DEGREE_TYPES } from "../../constants";
const validDegreeTypes = Object.keys(DEGREE_TYPES);

const checkIfFieldIsValidToShow = (degreeType, field) => validDegreeTypes.includes(degreeType) && SPECIAL_FIELDS_TO_SHOW_BY_DEGREE_TYPE[degreeType].includes(field);

const useConditionallyShowFormFields = degreeType => {
  const [showIngressModalityOptions, setShowIngressModalityOptions] = useState(checkIfFieldIsValidToShow(degreeType, INGRESS_MODALITY));
  useEffect(() => {
    setShowIngressModalityOptions(checkIfFieldIsValidToShow(degreeType, INGRESS_MODALITY));
  }, [degreeType]);
  return {
    showIngressModalityOptions
  };
};

export default useConditionallyShowFormFields;