const cutOffScorePercentage = {
  read(cached, _ref) {
    let {
      readField
    } = _ref;
    if (cached) return cached;
    const cutOffScore = readField('cutOffScore');
    return cutOffScore / 100;
  }

};
export default {
  fields: {
    cutOffScorePercentage
  }
};