import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["loading", "data"],
      _excluded2 = ["loading", "data"],
      _excluded3 = ["loading", "data"],
      _excluded4 = ["loading", "data"],
      _excluded5 = ["loading", "data"],
      _excluded6 = ["loading", "data"],
      _excluded7 = ["loading", "data"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useMutation, useQuery } from '../hooks';
import { ANSWER_CANDIDATE_EXAM, CONFIRM_CANDIDATE_EMAIL, CREATE_CANDIDATE, CREATE_CANDIDATE_CONTRACT, EXISTS_BY_CANDIDATE_DOCUMENT, GET_CANDIDATE, GET_CANDIDATE_EXAM, GET_CANDIDATE_PAYMENT_INFO, GET_CANDIDATE_STATUS, UPDATE_CANDIDATE_STATUS, RESET_CANDIDATE_ENEM_OPTION, SIGN_CANDIDATE_CONTRACT, UPDATE_CANDIDATE_CONTRACT_INFO, CANCEL_PENDING_PAYMENT, REDO_CANDIDATE_EXAM, VALIDATE_REDO_CANDIDATE_EXAM, COUNT_ENROLLED_CANDIDATES, CREATE_CANDIDATE_FROM_STUDENT, LIST_MY_REGISTRATIONS, IS_REENTRY_CANDIDATE, UPDATE_CANDIDATE_SELLER_ID } from './queries';
import { answerCandidateExamSchema, confirmCandidateEmailSchema, createCandidateLegacySchema, createCandidateSchema, useUpdateCandidateStatusSchema, signCandidateContractSchema, updateCandidateContractInfoSchema } from './schemas';
export const useExistsByCandidateDocument = function (doc) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery = useQuery(EXISTS_BY_CANDIDATE_DOCUMENT, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      document: doc
    },
    skip: !doc
  })),
        {
    loading,
    data
  } = _useQuery,
        rest = _objectWithoutProperties(_useQuery, _excluded);

  const exists = loading || !data ? null : data.data;
  return _objectSpread({
    data: exists,
    loading
  }, rest);
};
export const useLegacyCreateCandidate = options => {
  return [...useMutation(CREATE_CANDIDATE, options), createCandidateLegacySchema];
};
export const useCreateCandidate = options => {
  return [...useMutation(CREATE_CANDIDATE, options), createCandidateSchema];
};
export const useConfirmCandidateEmail = options => {
  return [...useMutation(CONFIRM_CANDIDATE_EMAIL, options), confirmCandidateEmailSchema];
};
export const useUpdateCandidateSellerId = function (candidateId, sellerId) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return [...useMutation(UPDATE_CANDIDATE_SELLER_ID, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId,
        sellerId
      }
    }],
    awaitRefetchQueries: true
  }))];
};
export const useGetCandidate = function (candidateId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery2 = useQuery(GET_CANDIDATE, _objectSpread(_objectSpread({
    skip: !candidateId
  }, options), {}, {
    variables: {
      candidateId: candidateId
    }
  })),
        {
    loading,
    data
  } = _useQuery2,
        rest = _objectWithoutProperties(_useQuery2, _excluded2);

  const candidate = loading || !data ? {} : data.data;
  return _objectSpread({
    candidate,
    loading
  }, rest);
};
export const useGetCandidateStatus = function (candidateId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery3 = useQuery(GET_CANDIDATE_STATUS, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      candidateId: candidateId
    }
  })),
        {
    loading,
    data
  } = _useQuery3,
        rest = _objectWithoutProperties(_useQuery3, _excluded3);

  const candidate = loading || !data ? {} : data.data;
  return _objectSpread({
    data: candidate,
    loading
  }, rest);
};
export const useGetCandidateExam = function (candidateId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery4 = useQuery(GET_CANDIDATE_EXAM, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      candidateId: candidateId
    }
  })),
        {
    loading,
    data
  } = _useQuery4,
        rest = _objectWithoutProperties(_useQuery4, _excluded4);

  const candidate = loading || !data ? {} : data.data;
  return _objectSpread({
    data: candidate,
    loading
  }, rest);
};
export const useAnswerCandidateExam = function (candidateId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [...useMutation(ANSWER_CANDIDATE_EXAM, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId
      }
    }],
    awaitRefetchQueries: true
  })), answerCandidateExamSchema];
};
export const useUpdateCandidateContractInfo = function (candidateId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [...useMutation(UPDATE_CANDIDATE_CONTRACT_INFO, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId
      }
    }],
    awaitRefetchQueries: true
  })), updateCandidateContractInfoSchema];
};
export const useCreateCandidateContract = options => {
  return useMutation(CREATE_CANDIDATE_CONTRACT, options);
};
export const useSignCandidateContract = function (candidateId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [...useMutation(SIGN_CANDIDATE_CONTRACT, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId
      }
    }],
    awaitRefetchQueries: true
  })), signCandidateContractSchema];
};
export const useResetCandidateEnemOption = function (candidateId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useMutation(RESET_CANDIDATE_ENEM_OPTION, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId
      }
    }],
    awaitRefetchQueries: true
  }));
};
export const useUpdateCandidateStatus = function (candidateId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [...useMutation(UPDATE_CANDIDATE_STATUS, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId
      }
    }],
    awaitRefetchQueries: true
  })), useUpdateCandidateStatusSchema];
};
export const useGetCandidatePaymentInfo = function (candidateId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery5 = useQuery(GET_CANDIDATE_PAYMENT_INFO, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      candidateId: candidateId
    }
  })),
        {
    loading,
    data
  } = _useQuery5,
        rest = _objectWithoutProperties(_useQuery5, _excluded5);

  const candidatePaymentInfo = loading || !data ? {} : data.data;
  return _objectSpread({
    data: candidatePaymentInfo,
    loading
  }, rest);
};
export const useCancelPendingPayment = function (candidateId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [...useMutation(CANCEL_PENDING_PAYMENT, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId
      }
    }, {
      query: GET_CANDIDATE_PAYMENT_INFO,
      variables: {
        candidateId
      }
    }],
    awaitRefetchQueries: true
  }))];
};
export const useRedoCandidateExam = function (candidateId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [...useMutation(REDO_CANDIDATE_EXAM, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [{
      query: GET_CANDIDATE,
      variables: {
        candidateId
      }
    }],
    awaitRefetchQueries: true,
    variables: {
      candidateId: candidateId
    }
  }))];
};
export const useValidateRedoCandidateExam = function (candidateId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    loading,
    data
  } = useQuery(VALIDATE_REDO_CANDIDATE_EXAM, _objectSpread(_objectSpread({}, options), {}, {
    refetchQueries: [],
    awaitRefetchQueries: true,
    variables: {
      candidateId: candidateId
    }
  }));
  return {
    data: loading || !data ? null : data.data,
    loading
  };
};
export const useCountEnrolledCandidates = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    loading,
    data
  } = useQuery(COUNT_ENROLLED_CANDIDATES, options);
  return {
    data: loading || !data ? null : data.countEnrolledCandidates,
    loading
  };
};
export const useListMyRegistrations = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  const _useQuery6 = useQuery(LIST_MY_REGISTRATIONS, _objectSpread({}, options)),
        {
    loading,
    data
  } = _useQuery6,
        rest = _objectWithoutProperties(_useQuery6, _excluded6);

  const candidatesByDocument = loading || !data ? [] : data.data;
  return _objectSpread({
    data: candidatesByDocument,
    loading
  }, rest);
};
export const useCreateCandidateFromStudent = function (courseId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useMutation(CREATE_CANDIDATE_FROM_STUDENT, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseId
    }
  }));
};
export const useIsReentryCandidate = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  const _useQuery7 = useQuery(IS_REENTRY_CANDIDATE, _objectSpread({}, options)),
        {
    loading,
    data
  } = _useQuery7,
        rest = _objectWithoutProperties(_useQuery7, _excluded7);

  const isReentryCandidate = loading || !data ? null : data.data;
  return _objectSpread({
    data: isReentryCandidate,
    loading
  }, rest);
};