import { useState } from 'react';
import { useDialogState } from '@ampli/ui';
import { pushAddProductToCart } from "../lib/datalayer-modal-events";

const useSubscriptionModal = (course, price) => {
  const [state, setState] = useState({});
  const dialog = useDialogState();

  const show = newState => {
    if (!course) course = {};
    pushAddProductToCart(course.name, course.id, price);
    setState(newState);
    dialog.show();
  };

  return {
    state,
    show,
    dialog
  };
};

export default useSubscriptionModal;