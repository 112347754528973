import { isString } from '@ampli/utils';
export { name, version } from '../../package.json';

export const ampli =
  (process.env.AMPLI_ENV &&
    require(`@ampli/config/src/${process.env.AMPLI_ENV}`)) ||
  (typeof window !== 'undefined' && window.__AMPLI__.ampli) ||
  JSON.parse(process.env.REACT_APP_AMPLI).ampli;

export const squad =
  process.env.SQUAD_NAME ||
  (typeof window !== 'undefined' && window.__AMPLI__.squad);

export const baseUrl = ampli.baseUrl[squad] || ampli.baseUrl;
export const graphqlUrl = ampli.graphqlUrl[squad] || ampli.graphqlUrl;
export const loginUrl = ampli.loginUrl[squad] || ampli.loginUrl;
export const followSubscriptionUrl =
  ampli.followSubscriptionUrl[squad] || ampli.followSubscriptionUrl;
export const entranceExamUrl =
  ampli.entranceExamUrl[squad] || ampli.entranceExamUrl;

if (!isString(graphqlUrl)) {
  console.debug({ ampli, graphqlUrl });
  throw new Error('GraphQL URL must be a string.');
}

export const isLocal =
  typeof location !== 'undefined' &&
  (location.hostname === 'localhost' || location.hostname === '127.0.0.1');
export const isProduction = ampli.env === 'production';
export const isStaging = ampli.env === 'staging';
export const isIntegration = ampli.env === 'integration';

export const logLevel = isProduction ? 'error' : 'debug';

export const googleAPIKey = 'AIzaSyCNseeK7DRB1QeBDoxfcxkBfW8BsVnCjRQ';

export const recaptchaSiteKey = '6Ldb0cYUAAAAAN-q85BZljFbiyBdwRGOZRhSdQUM';

export const sentryDsn =
  'https://0419bf58e224485ab96bcfabd8ebcb09@sentry.kroton.tech/21';

export const showSubscriptionPageOne = () =>
  window.open(`${baseUrl}/inscricao/cadastro`, '_self');

export const RvBaseUrl = isStaging
  ? 'https://stg.matriculas.ampli.com.br/'
  : 'https://matriculas.ampli.com.br/';

export const RvSubscriptionBaeUrl = RvBaseUrl + 'inscricao/dados-pessoais';
