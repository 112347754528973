import TagManager from 'react-gtm-module';
import { GTM } from "./tracking-ids";

const gtmInit = context => {
  const gtmId = GTM[context];
  gtmId ? TagManager.initialize({
    gtmId
  }) : console.error(`context [${context}] is not valid`);
};

export default gtmInit;