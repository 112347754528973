import { useMutation } from '../hooks';
import { CREATE_LEAD, CREATE_EMAIL_LEAD, NEW_CREATE_LEAD, UPDATE_LEAD } from './queries';
import { createEmailLeadSchema, createLeadSchema, createLeadByNameSchema, // createNewLeadSchema,
updateLeadSchema } from './schemas';
export const useCreateLead = options => {
  return [...useMutation(CREATE_LEAD, options), createLeadSchema];
};
export const useCreateLeadByName = options => {
  return [...useMutation(CREATE_LEAD, options), createLeadByNameSchema];
};
export const useCreateEmailLead = options => {
  return [...useMutation(CREATE_EMAIL_LEAD, options), createEmailLeadSchema];
}; // export const useNewCreateLead = (options) => {
//   return [...useMutation(NEW_CREATE_LEAD, options), createNewLeadSchema];
// };

export const useUpdateLead = options => {
  return [...useMutation(UPDATE_LEAD, options), updateLeadSchema];
};