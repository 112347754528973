import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/* eslint-disable sonarjs/no-identical-functions */
import { useEffect, useCallback } from 'react';
import { isEmpty, map, without, concat, trim, includes, omit, useFormik, phoneFormatter, isString } from '@ampli/utils';
import { useCreateLead, useUpdateLead, useCreateCandidate, createCandidateSchemaFirstStep, createCandidateSchemaSecondStep, createCandidateSchemaThirdStep, queryStringsToCache } from '@ampli/services';
import { pushProductPurchase } from "../lib/datalayer-modal-events";
const reCaptchaMessage = "Google reCaptcha doesn't exist.";

const useErrorCallback = (called, loading, error, onError) => {
  const onErrorCallback = useCallback(onError, []);
  useEffect(() => {
    if (called && !loading && error) {
      onErrorCallback(error);
    }
  }, [called, loading, error, onErrorCallback]);
};

export const useSubscriptionCandidateFirstStepForm = _ref => {
  let {
    settings = {},
    initialValues = null,
    candidateAdditionalData = {},
    onSubmit,
    onError,
    onCompleted
  } = _ref;
  const [createLead, {
    data,
    called,
    loading,
    error
  }] = useCreateLead({
    onError,
    onCompleted
  });

  const isUnparsedPhone = phone => {
    const unparsedPhoneRegex = /^\d{9}$/;
    return !unparsedPhoneRegex.test(phone);
  };

  const {
    getQueryStringsFromCache
  } = queryStringsToCache;

  const handleCreateLead = async (values, captcha) => {
    const queryStringsMap = await getQueryStringsFromCache();
    const sellerId = queryStringsMap.sellerId;
    const queryStrings = Object.entries(queryStringsMap).map(m => ({
      field: m[0],
      value: m[1]
    }));
    const sourceTag = candidateAdditionalData === null || candidateAdditionalData === void 0 ? void 0 : candidateAdditionalData.sourceTag;

    const lead = _objectSpread(_objectSpread(_objectSpread({}, omit(values, 'id')), sourceTag && {
      sourceTag
    }), {}, {
      sellerId,
      queryStrings
    });

    createLead({
      variables: {
        lead,
        captcha
      }
    });
    onSubmit && onSubmit(values);
  };

  const handleUnparsedPhone = phone => {
    return isString(phone) && isUnparsedPhone(phone) ? phoneFormatter(phone) : phone;
  };

  const formik = useFormik({
    initialValues: {
      email: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.email) || '',
      phone: handleUnparsedPhone(initialValues === null || initialValues === void 0 ? void 0 : initialValues.phone) || '',
      name: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.name) || '',
      optIn: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.optIn) || false
    },
    validationSchema: createCandidateSchemaFirstStep,
    onSubmit: values => {
      var _window;

      const grecaptcha = (_window = window) === null || _window === void 0 ? void 0 : _window.grecaptcha;

      if (grecaptcha) {
        grecaptcha.ready(() => {
          return grecaptcha.execute(settings.recaptchaSiteKey, {
            action: 'createCandidate'
          }).then(token => {
            return handleCreateLead(values, {
              token,
              origin: 'web'
            });
          });
        });
      } else {
        throw new Error(reCaptchaMessage);
      }
    }
  });
  useErrorCallback(called, loading, error, onError);
  return {
    formik,
    data,
    loading,
    called,
    error
  };
};
export const useSubscriptionCandidateSecondStepForm = _ref2 => {
  let {
    settings = {},
    candidateAdditionalData = {},
    initialValues = null,
    onSubmit,
    onError,
    onCompleted
  } = _ref2;
  const [updateLead, {
    data,
    called,
    loading,
    error
  }] = useUpdateLead({
    onError,
    onCompleted
  });

  const handleUpdateLead = (values, captcha) => {
    const lead = _objectSpread(_objectSpread({}, omit(candidateAdditionalData, ['id'])), {}, {
      courseId: values.courseId
    });

    updateLead({
      variables: {
        id: candidateAdditionalData.id,
        lead,
        captcha
      }
    });
    onSubmit && onSubmit(values);
  };

  const formik = useFormik({
    initialValues: {
      courseType: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.courseType) || 'UNDERGRADUATE',
      courseId: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.courseId) || ''
    },
    enableReinitialize: true,
    validationSchema: createCandidateSchemaSecondStep(settings.degreeTypes),
    onSubmit: values => {
      var _window2;

      const grecaptcha = (_window2 = window) === null || _window2 === void 0 ? void 0 : _window2.grecaptcha;

      if (grecaptcha) {
        grecaptcha.ready(() => {
          return grecaptcha.execute(settings.recaptchaSiteKey, {
            action: 'createCandidate'
          }).then(token => {
            return handleUpdateLead(values, {
              token,
              origin: 'web'
            });
          });
        });
      } else {
        throw new Error(reCaptchaMessage);
      }
    }
  });
  useErrorCallback(called, loading, error, onError);
  return {
    formik,
    data,
    loading,
    called,
    error
  };
};
export const useSubscriptionCandidateStep3Form = _ref3 => {
  let {
    settings = {},
    candidateAdditionalData,
    schemaOptions = {},
    onSubmit,
    onError,
    onSuccess
  } = _ref3;
  const [createCandidate, {
    data,
    called,
    loading,
    error
  }] = useCreateCandidate(schemaOptions);
  const {
    getQueryStringsFromCache
  } = queryStringsToCache;

  const handleCreateCandidate = async (values, captcha) => {
    let specialNeeds = values.specialNeeds;
    const additionalSpecialNeeds = trim(values.additionalSpecialNeeds);
    const enemOption = values.enemOption !== null ? values.enemOption === '1' : null;
    const document = values.document.replace(/[^\d]/g, '');
    const birthDate = values.birthDate.split('/').reverse().join('-');
    const queryStringsMap = await getQueryStringsFromCache();
    const sellerId = queryStringsMap.sellerId;
    const queryStrings = Object.entries(queryStringsMap).map(m => ({
      field: m[0],
      value: m[1]
    }));

    if (!isEmpty(additionalSpecialNeeds) && includes(specialNeeds, 'Outra')) {
      specialNeeds = concat(without(values.specialNeeds, 'Outra'), [additionalSpecialNeeds]);
    }

    specialNeeds = map(specialNeeds, description => ({
      description
    }));

    const candidate = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, omit(values, ['additionalSpecialNeeds', 'specialNeedsOption', 'enemOption', 'courseName', 'coursePrice'])), omit(candidateAdditionalData, ['leadStateId', 'courseType', 'courseName', 'coursePrice', 'name', 'phone', 'id', 'sourceTag'])), enemOption !== null && {
      enemOption
    }), {}, {
      fullName: candidateAdditionalData.name,
      mobilePhone: candidateAdditionalData.phone,
      document,
      birthDate,
      specialNeeds,
      sellerId,
      queryStrings,
      isFreeTrialOption: candidateAdditionalData.isFreeTrialOption,
      affiliateId: candidateAdditionalData.affiliateId
    });

    createCandidate({
      variables: {
        candidate,
        captcha
      }
    });
    onSubmit && onSubmit(candidate);
  };

  const formik = useFormik({
    initialValues: {
      enemOption: null,
      document: '',
      birthDate: '',
      specialNeeds: [],
      specialNeedsOption: '0',
      additionalSpecialNeeds: ''
    },
    validationSchema: createCandidateSchemaThirdStep(schemaOptions),
    onSubmit: values => {
      var _window3;

      const grecaptcha = (_window3 = window) === null || _window3 === void 0 ? void 0 : _window3.grecaptcha;

      if (grecaptcha) {
        grecaptcha.ready(() => {
          return grecaptcha.execute(settings.recaptchaSiteKey, {
            action: 'createCandidate'
          }).then(token => {
            return handleCreateCandidate(values, {
              token,
              origin: 'web'
            });
          });
        });
      } else {
        throw new Error(reCaptchaMessage);
      }
    }
  });
  let courseName = candidateAdditionalData.courseName;
  let courseId = candidateAdditionalData.courseId;
  let coursePrice = candidateAdditionalData.coursePrice;
  let candidateId = candidateAdditionalData.id;

  const sendDataLayerPurchaseEvent = () => {
    pushProductPurchase(courseName, courseId, coursePrice, candidateId);
  };

  const onSuccessCallback = useCallback(onSuccess, []);
  useErrorCallback(called, loading, error, onError);
  useEffect(() => {
    if (called && !loading && !error) {
      sendDataLayerPurchaseEvent();
      onSuccessCallback(data);
    }
  }, [called, loading, error, data, onSuccessCallback]);
  return {
    formik,
    loading,
    called,
    error,
    data
  };
};