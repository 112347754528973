import * as Yup from 'yup';
const requiredMsg = 'Preenchimento obrigatório.';
const invalidEmailMsg = 'E-mail inválido.';
const invalidPhoneMsg = 'Telefone inválido.';
const fullnameMsg = 'Informe seu nome e sobrenome.';
export const createEmailLeadSchema = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Yup.object({
    email: Yup.string().required(requiredMsg).email(invalidEmailMsg)
  });
};
export const createLeadByNameSchema = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Yup.object({
    phone: Yup.string().required(requiredMsg).matches(/\(\d\d\)\s9\d{4}-\d{4}/, invalidPhoneMsg),
    name: Yup.string().required(requiredMsg)
  });
};
export const createLeadSchema = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Yup.object({
    name: Yup.string().notRequired().matches(/[a-z]{3,}\s([a-z]+)+/i, fullnameMsg),
    phone: Yup.string().required(requiredMsg).matches(/\(\d\d\)\s9\d{4}-\d{4}/, invalidPhoneMsg),
    email: Yup.string().required(requiredMsg).email(invalidEmailMsg),
    optIn: Yup.boolean().notRequired()
  });
};
export const updateLeadSchema = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Yup.object({
    id: Yup.string().required(requiredMsg),
    name: Yup.string().required(requiredMsg).matches(/[a-z]{3,}\s([a-z]+)+/i, fullnameMsg),
    phone: Yup.string().required(requiredMsg),
    email: Yup.string().required(requiredMsg).email(invalidEmailMsg),
    optIn: Yup.boolean().notRequired(),
    courseId: Yup.string().notRequired(),
    postalCode: Yup.string(),
    cityId: Yup.number(),
    stateId: Yup.number()
  });
};