import { calculateProgress } from '@ampli/utils';

const readPercentage = (cached, _ref) => {
  let {
    readField
  } = _ref;
  if (cached) return cached;
  const {
    percentage
  } = calculateProgress(readField('completed') || 0, readField('quantity'));
  return percentage;
};

const percentage = {
  read: readPercentage
};
const percentageLabel = {
  read(cached, _ref2) {
    let {
      readField
    } = _ref2;
    if (cached) return cached;
    const percentage = readPercentage(null, {
      readField
    });
    const percentageValue = percentage > 1 ? 1 : percentage;
    return `${Math.round(percentageValue * 100)}%`;
  }

};
export default {
  fields: {
    percentage,
    percentageLabel
  }
};