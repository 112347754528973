import { kebabCase } from '@ampli/utils';
const cityName = {
  read(cached, _ref) {
    let {
      readField
    } = _ref;
    if (cached) return cached;
    const cityRef = readField('city');
    return readField('name', cityRef);
  }

};
const stateAcronym = {
  read(cached, _ref2) {
    let {
      readField
    } = _ref2;
    if (cached) return cached;
    const stateRef = readField('state');
    return readField('acronym', stateRef);
  }

};
export default {
  keyFields: address => {
    return `Address:${btoa(kebabCase(JSON.stringify(address)))}`;
  },
  fields: {
    cityName,
    stateAcronym
  }
};