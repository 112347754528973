import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["loading", "data"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useQuery } from '../hooks';
import { FIND_ALL_LEGAL_DOCUMENTS } from './queries';
export const useFindAllLegalDocuments = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  const _useQuery = useQuery(FIND_ALL_LEGAL_DOCUMENTS, _objectSpread({
    fetchPolicy: 'no-cache'
  }, options)),
        {
    loading,
    data
  } = _useQuery,
        rest = _objectWithoutProperties(_useQuery, _excluded);

  return _objectSpread({
    data,
    loading
  }, rest);
};