import React, { FunctionComponent } from 'react';
import { cx } from '@ampli/ui';
import { string } from '@ampli/utils';

import DegreeCard from './degree-card';
import styles from './styles';
import { DISABLE_FREE_COURSES, DISABLE_GRADUATE } from '../../constants';

export interface CardWrapperProps {
  className?: string;
  defaultParams: string;
}

const CardWrapper: FunctionComponent<CardWrapperProps> = ({
  className,
  defaultParams,
  ...props
}: CardWrapperProps) => {
  return (
    <div
      className={cx('card-wrapper', styles.cardWrapper, className)}
      {...props}
    >
      <div className={cx('card-wrapper-content', styles.cardWrapperContent)}>
        <DegreeCard
          illustration="RunningMan"
          title="Graduação"
          link={'/graduacao' + defaultParams}
        >
          Cursos bacharelados, licenciaturas e tecnólogos a distância.
        </DegreeCard>
        {!DISABLE_GRADUATE && (
          <DegreeCard
            illustration="RunningLady"
            title="Pós-"
            subtitle="Graduação"
            link={'/pos-graduacao' + defaultParams}
          >
            Cursos de especialização e MBA online.
          </DegreeCard>
        )}
      </div>
      <div className={cx('card-wrapper-content', styles.cardWrapperContent)}>
        {!DISABLE_FREE_COURSES && (
          <DegreeCard
            illustration="PlayingTennis"
            title="Cursos"
            subtitle="Livres"
            link="https://cursolivre.ampli.com.br/"
            externalLink
            className={cx(styles.degreeCardCourses)}
          >
            Cursos de curta duração com certificado.
          </DegreeCard>
        )}
        <DegreeCard
          illustration="RunningGirl"
          title="Curso"
          subtitle="Técnico"
          link={'/cursos/eja' + defaultParams}
        >
          Cursos técnicos para entrar no mercado de trabalho em até 1 ano e
          meio.
        </DegreeCard>
      </div>
    </div>
  );
};

CardWrapper.propTypes = {
  className: string,
};

export default CardWrapper;
