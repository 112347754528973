import { pathUrlField } from './commons';
const assets = {
  read(cached, _ref) {
    let {
      readField
    } = _ref;
    if (cached) return cached;
    return [{
      captionLink: null,
      id: readField('id'),
      mediastreamId: readField('mediaId'),
      channelId: readField('channelId'),
      size: '',
      type: 'VIDEO',
      vodUrl: '',
      __typename: 'VideoAsset'
    }];
  }

};
export default {
  fields: {
    pathUrl: pathUrlField,
    assets: assets
  }
};