import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { queryStringsToCache } from '@ampli/services';

const useSaveQueryStringsToCache = (keyIgnoreList) => {
  const { saveQueryStringsToCache } = queryStringsToCache;
  const queryStringFromUrl = useLocation().search;

  useEffect(() => {
    saveQueryStringsToCache(queryStringFromUrl, keyIgnoreList);
  }, [keyIgnoreList]);
};

export default useSaveQueryStringsToCache;
