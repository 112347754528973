import { useState, useEffect } from 'react';
import { getCurrentTime as getCurrentTimeFromInternet } from '@ampli/services';
export default function useCheckDateIsInPeriod(startDate, endDate, dateAPIURL) {
  const [isValidDate, setIsValidDate] = useState(null);
  const [loading, setLoading] = useState(false);

  function validateDate(recievedDate) {
    return startDate <= recievedDate && recievedDate < endDate;
  }

  async function checkIfIsValidDate() {
    try {
      setLoading(true);
      const date = await getCurrentTimeFromInternet(dateAPIURL);
      date && setIsValidDate(validateDate(date));
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    checkIfIsValidDate();
  }, []);
  return {
    isValidDate,
    loading
  };
}