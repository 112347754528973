/* eslint-disable sonarjs/no-duplicate-string */
import { loginUrl, followSubscriptionUrl } from './config';

export const STORES = {
  GOOGLEPLAY: {
    URL: 'https://play.google.com/store/apps/details?id=br.com.ampli',
  },
  APPLESTORE: {
    URL: 'https://apps.apple.com/us/app/id1503246413',
  },
};

export const ANHANGUERA_STORES = {
  GOOGLEPLAY: {
    URL: 'https://play.google.com/store/apps/details?id=br.com.cogna.ava',
  },
  APPLESTORE: {
    URL: 'https://apps.apple.com/us/app/anhanguera/id6446199719',
  },
};

export const CONTACT = {
  WHATSAPP: 'https://wa.me/551940422271',
};

export const AEDU_CONTACT = {
  WHATSAPP: 'https://wa.me/551940422271',
};

export const HEADER_LINKS = {
  login: loginUrl,
  followSubscription: followSubscriptionUrl,
  cursosLivres: 'https://cursolivre.ampli.com.br/',
  cursoTecnico: 'https://cogna.ubpages.com/ensino-tecnico/',
  FAQ: 'https://duvidas.ampli.com.br/',
  blog: 'https://blog.ampli.com.br/',
  aeduBlog: 'https://blog.anhanguera.com/',
  aeduLogin: 'https://www.anhanguera.com/apprendemais',
  aeduFAQ: 'https://www.anhanguera.com/paginas/central-de-atendimento/',
};

export const COURSE_DURATION_VALUES = {
  '5-meses': 5,
  '6-meses': 6,
  '7-meses': 7,
  '8-meses': 8,
  '9-meses': 9,
  '1-ano': 12,
  '1-ano-e-meio': 18,
  '2-anos': 24,
  '2-anos-e-meio': 30,
  '3-anos': 36,
};

export const SUBJECT_LIST_DIVISION_TYPES = {
  SEMESTER: 'SEMESTER',
  LIST: 'LIST',
};

export const COURSE_DEGREE_TYPES = {
  UNDERGRADUATE: 'UNDERGRADUATE',
  GRADUATE: 'GRADUATE',
  EJA: 'EJA',
  // TECHNICAL: 'TECHNICAL',
};

const { UNDERGRADUATE, GRADUATE, EJA } = COURSE_DEGREE_TYPES;

export const CONFIG_BY_DEGREE_TYPE = {
  PATHNAME: {
    [UNDERGRADUATE]: {
      label: 'Graduação',
      pathname: '/graduacao',
      coursePathname: '/graduacao',
    },
    [GRADUATE]: {
      label: 'Pós-graduação',
      pathname: '/pos-graduacao',
      coursePathname: '/pos-graduacao',
    },
    [EJA]: {
      label: 'EJA/Técnico',
      pathname: '/cursos/eja',
      coursePathname: '/eja',
    },
    // [TECHNICAL]: {
    //   label: 'Técnico',
    //   pathname: '/cursos/tecnico',
    //   coursePathname: '/tecnico',
    // },
  },
  COURSE_LIST_FILTERS: {
    [UNDERGRADUATE]: [
      {
        name: 'courseDuration',
        items: [
          { label: '1 ano e meio', value: '1-ano-e-meio' },
          { label: '2 anos', value: '2-anos' },
          { label: '2 anos e meio', value: '2-anos-e-meio' },
          { label: '3 anos', value: '3-anos' },
        ],
        type: 'groupButtons',
        label: 'Tempo de formação',
        extraInfo: 'em até',
      },
      {
        name: 'fieldOfStudy',
        items: [],
        type: 'checkbox',
        label: 'Área de atuação',
        wrap: true,
      },
      {
        name: 'courseType',
        items: [
          { label: 'Tecnólogo', value: 'tecnologo' },
          { label: 'Bacharelado', value: 'bacharelado' },
          { label: 'Licenciatura', value: 'licenciatura' },
        ],
        type: 'checkbox',
        label: 'Tipos de graduação',
      },
    ],
    [GRADUATE]: [
      {
        name: 'courseDuration',
        items: [
          { label: '5 meses', value: '5-meses' },
          { label: '6 meses', value: '6-meses' },
          { label: '7 meses', value: '7-meses' },
          { label: '8 meses', value: '8-meses' },
          { label: '9 meses', value: '9-meses' },
        ],
        type: 'groupButtons',
        label: 'Conclusão do curso',
        extraInfo: 'em até',
      },
      {
        name: 'fieldOfStudy',
        items: [],
        type: 'checkbox',
        label: 'Área de atuação',
        wrap: true,
      },
      {
        name: 'courseType',
        items: [
          { label: 'Especialização', value: 'pos-graduacao' },
          { label: 'MBA', value: 'mba' },
        ],
        type: 'checkbox',
        label: 'Tipos de pós-graduação',
      },
    ],
    [EJA]: [
      {
        name: 'courseDuration',
        items: [
          { label: '6 meses', value: '6-meses' },
          { label: '1 ano e meio', value: '1-ano-e-meio' },
          { label: '2 anos', value: '2-anos' },
        ],
        type: 'groupButtons',
        label: 'Conclusão do curso',
        extraInfo: 'em até',
      },
    ],
    // [TECHNICAL]: [
    //   {
    //     name: 'courseDuration',
    //     items: [{ label: '1 ano e meio', value: '1-ano-e-meio' }],
    //     type: 'groupButtons',
    //     label: 'Conclusão do curso',
    //     extraInfo: 'em até',
    //   },
    // ],
  },

  DURATION_TYPES: {
    [UNDERGRADUATE]: 'months',
    [GRADUATE]: 'months',
    [EJA]: 'months',
    // [TECHNICAL]: 'months',
  },
  METADATA: {
    defaultTitle: {
      [UNDERGRADUATE]: 'Cursos de Graduação EAD | Ampli',
      [GRADUATE]: 'Cursos de Pós-graduação EAD | Ampli',
      [EJA]: 'Educação para Jovens e Adultos (EJA) - Supletivo | Ampli',
      // [TECHNICAL]: 'Cursos Técnicos | Ampli',
    },
    filterMetaInformation: {
      [UNDERGRADUATE]: {
        title: 'Graduação',
        courseType: 'graduação',
        minTime: '1 ano e meio',
        brandText:
          ' da Ampli. São diversas opções, com polos EAD espalhados por todo o Brasil.',
      },
      [GRADUATE]: {
        title: 'Pós-graduação',
        courseType: 'pós-graduação',
        minTime: '5 meses',
        brandText: ' da Ampli. Saiba mais.',
      },
      [EJA]: {
        title: 'Ensino de Jovens e Adultos',
        courseType: 'EJA',
        minTime: '6 meses',
        brandText: ' da Ampli. Saiba mais.',
      },
      // [TECHNICAL]: {
      //   title: 'Técnico',
      //   courseType: 'ensino técnico',
      //   minTime: '1 ano',
      //   brandText: ' da Ampli. Saiba mais.',
      // },
    },
    defaultDescription: {
      [UNDERGRADUATE]:
        'Cursos de graduação a distância (EAD). Licenciatura, Bacharelado e Tecnólogo em diversas áreas. Cursos de 1 ano e meio até 3 anos de duração. Inscreva-se!',
      [GRADUATE]:
        'Cursos de pós-graduação a distância. Especialização e MBA online em diversas áreas. Cursos de 5 meses até 9 meses de duração. Faça a sua matrícula!',
      [EJA]:
        'Conheça os módulos de ensino da Educação de Jovens e Adultos (EJA). Conclua o Ensino Fundamental e Ensino Médio estudando pelo seu celular. Saiba mais!',
      // [TECHNICAL]:
      //   'Cursos técnicos para entrar no mercado de trabalho em até 1 ano e meio. Saiba mais!',
    },
  },
  COURSE_LIST_BANNER_DESCRIPTION: {
    [UNDERGRADUATE]: { title: 'Graduação', lowerCase: true },
    [GRADUATE]: { title: 'Pós-Graduação', lowerCase: true },
    [EJA]: { title: 'Técnico' },
    // [TECHNICAL]: { title: 'Ensino Técnico', lowerCase: true },
  },
  SUBJECT_LIST_DIVISION: {
    [UNDERGRADUATE]: SUBJECT_LIST_DIVISION_TYPES.SEMESTER,
    [GRADUATE]: SUBJECT_LIST_DIVISION_TYPES.LIST,
    [EJA]: SUBJECT_LIST_DIVISION_TYPES.LIST,
    // [TECHNICAL]: SUBJECT_LIST_DIVISION_TYPES.LIST,
  },
};

export const {
  PATHNAME: PATHNAME_BY_DEGREE_TYPE,
  COURSE_LIST_FILTERS: COURSES_LIST_FILTERS_BY_DEGREE_TYPE,
  DURATION_TYPES: COURSE_DEGREE_DURATION_TYPES,
  METADATA,
  COURSE_LIST_BANNER_DESCRIPTION,
  SUBJECT_LIST_DIVISION,
} = CONFIG_BY_DEGREE_TYPE;

export const COURSE_FILTERS_TO_URL_PARAMS = {
  name: 'nome',
  courseDuration: 'duracao',
  fieldOfStudy: 'area',
  courseType: 'tipo',
  page: 'pagina',
  freeTrialOption: '4357c962ab78a3963f336908352c3adf',
};

export const ARRAY_URL_PARAMS = ['fieldOfStudy', 'courseType'];

export const SCROLLER_SETTINGS = {
  isInViewportSettings: {
    modBottom: '-70%',
  },
};

export const MIN_WIDTH_DESKTOP_HEADER = 1152;

export const BENEFITS_TOOLTIP_TEXTS = {
  '1º mês grátis':
    'Faça sua matrícula e o primeiro mês de aula é por nossa conta. Você não paga nada! Se você não gostar do curso, é possível cancelar a matrícula dentro desse período sem pagar nenhuma multa.',
  'Forme mais rápido':
    'Aqui permitimos que o aluno emende um módulo no outro. Dessa forma, você pode antecipar suas aulas no período de férias (janeiro, julho e dezembro) e se formar mais rápido.',
  'Valor único':
    'Nossas mensalidades são fixas, reajustadas anualmente apenas pela inflação.',
  'Aula pelo celular':
    'Tenha a praticidade de estudar através do nosso aplicativo, quando quiser, em qualquer lugar. Você ainda tem a opção de utilizar o Wi-Fi para fazer download das aulas para estudar off-line.',
};

export const EJACourseLevelURLCodes = {
  highSchool: 'eja---ensino-medio',
  middleSchool: 'eja---ensino-fundamental',
  ninthGradeMiddleSchool: 'eja---ensino-fundamental---9-ano',
  thirdGradeHighSchool: 'eja---ensino-medio---3-ano',
  seventhToNinthGradeMiddleSchool: 'eja---ensino-fundamental---7-8-e-9-ano',
  eighthToNinthGradeMiddleSchool: 'eja---ensino-fundamental---8-e-9-ano',
  secondToThirdGradeHighSchool: 'eja---ensino-medio---2-e-3-ano',
};

export const TechnicalCourseURLCodes = {
  administrationTechnician: 'tecnico-em-administracao',
  environmentalTechnician: 'tecnico-em-meio-ambiente',
  itMaintenanceAndSupportTechnician:
    'tecnico-em-manutencao-e-suporte-em-informatica',
  marketingTechnician: 'tecnico-em-marketing',
  systemsDevelopmentTechnician: 'tecnico-em-desenvolvimento-de-sistemas',
  aestheticsTechnician: 'tecnico-em-estetica',
  massageTherapyTechnician: 'tecnico-em-massoterapia',
  accountingTechnician: 'tecnico-em-contabilidade',
  logisticsTechnician: 'tecnico-em-logistica',
  electricalTechnician: 'tecnico-em-eletrotecnica',
  salesTechnician: 'tecnico-em-vendas',
};

export const EJASharedURLSearchParams = [
  { key: 'sellerId', value: '33e04435-1b9c-412c-9ff4-cae019a08576' },
  { key: 'utm_campaign', value: 'eja' },
  { key: 'utm_medium', value: 'share-button' },
];

export const TIM_BLACK_FRIDAY_START_DATE = new Date(
  Date.UTC(2021, 9, 24, 3, 0, 0, 0)
);

export const TIM_BLACK_FRIDAY_END_DATE = new Date(
  Date.UTC(2021, 11, 1, 3, 0, 0, 0)
);

export const TIM_PROMO_START_DATE = new Date(Date.UTC(2022, 5, 1, 3, 0, 0, 0));

export const TIM_PROMO_END_DATE = new Date(Date.UTC(2022, 9, 31, 23, 0, 0, 0));

export const AMPLI_BLACK_WEEK_START_DATE = new Date(
  Date.UTC(2022, 9, 24, 3, 0, 0, 0)
);

export const AMPLI_BLACK_WEEK_END_DATE = new Date(
  Date.UTC(2022, 10, 5, 2, 59, 59, 999)
);

export const DISABLE_FREE_COURSES = true;

export const DISABLE_GRADUATE = true;

export const FOOTER_SEO_CATEGORIES = [
  {
    title: 'Institucional',
    items: [
      {
        label: 'Cogna Educação',
        url: 'http://www.cogna.com.br/',
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        label: 'Trabalhe Conosco',
        url: 'https://jobs.kenoby.com/ampli',
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        label: 'Termos de Uso',
        url: 'https://ofertas.ampli.com.br/termos-de-uso',
      },
      {
        label: 'Política de Privacidade',
        url: 'https://www.canaldatransparencia.com.br/cogna/',
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        label: 'Polos EAD',
        url: '/polos-ead',
        isRelative: true,
      },
    ],
  },
  {
    title: 'Cursos',
    items: [
      {
        label: 'Graduação',
        url: '/graduacao',
        isRelative: true,
      },
      !DISABLE_GRADUATE && {
        label: 'Pós-graduação',
        url: '/pos-graduacao',
        isRelative: true,
      },
      !DISABLE_FREE_COURSES && {
        label: 'Cursos Livres',
        url: 'https://cursolivre.ampli.com.br/',
      },
      {
        label: 'Curso Técnico',
        url: '/cursos/eja',
        isRelative: true,
      },
    ],
  },
  {
    title: 'Estude com a gente',
    items: [
      {
        label: 'Processo Seletivo',
        url: 'https://ampli.com.br/processo-seletivo/login',
      },
      {
        label: 'AVA Ampli',
        url: 'https://ava.ampli.com.br/login',
      },
    ],
  },
  {
    title: 'Informações',
    items: [
      {
        label: 'Dúvidas Frequentes',
        url: 'https://duvidas.ampli.com.br/hc/pt-br',
      },
      {
        label: 'Parceria TIM',
        url: 'https://inscricao.ampli.com.br/parceria-ampli-e-tim',
      },
    ],
  },
];

export const ANHANGUERA_FOOTER_SEO_CATEGORIES = [
  {
    title: 'Institucional',
    items: [
      {
        label: 'Cogna Educação',
        url: 'http://www.cogna.com.br/',
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        label: 'Trabalhe Conosco',
        url: 'https://jobs.kenoby.com/ampli',
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        label: 'Termos de Uso',
        url: 'https://ofertas.ampli.com.br/termos-de-uso',
      },
      {
        label: 'Política de Privacidade',
        url: 'https://www.canaldatransparencia.com.br/cogna/',
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        label: 'Polos EAD',
        url: '/polos-ead',
        isRelative: true,
      },
    ],
  },
  {
    title: 'Cursos',
    items: [
      {
        label: 'Curso Técnico',
        url: '/cursos/eja',
        isRelative: true,
      },
    ],
  },
  {
    title: 'Estude com a gente',
    items: [
      {
        label: 'Processo Seletivo',
        url: 'https://ampli.com.br/processo-seletivo/login',
      },
      {
        label: 'AVA Anhanguera',
        url: 'https://www.anhanguera.com/apprendemais/login',
      },
    ],
  },
  {
    title: 'Informações',
    items: [
      {
        label: 'Dúvidas Frequentes',
        url: 'https://duvidas.ampli.com.br/hc/pt-br',
      },
      {
        label: 'Parceria TIM',
        url: 'https://inscricao.ampli.com.br/parceria-ampli-e-tim',
      },
    ],
  },
];

export const EJACoursesIds = {
  elementaryCourses: [
    'f9d5b163-1dc2-4f30-8574-ee3a5d5081ba',
    '940fcdc5-c8e7-46a7-bdb4-58e8e5f2b273',
    '9d493243-4662-46a0-b4ee-88f6f346c1da',
    '55d1a2d5-e96a-4796-98a1-24ad2bc781eb',
  ],
  highSchoolCourses: [
    '7668f270-3b88-4310-aba3-d6ee8d51c78a',
    'c5cd981a-af30-4ef0-955b-e241e807735c',
    'fdaa6f10-90e5-47d1-b1e9-8028c92afdb2',
  ],
};

export const FREE_TRIAL_TOTAL_DAYS = 15;

export const EJA_FREE_TRIAL_TOTAL_DAYS = 7;

export const ENGINEERING_COURSES_IDS = [
  'f7daf990-964d-47bc-ba3b-86b176fcb9b9',
  '51c91b83-79a7-433d-889d-13d85c9ad055',
  'd07282d0-e4c8-4048-b624-caa0a2f6b509',
  '6fa05485-aff6-490b-a547-e6edba156fea',
  'ee0e349d-9bb9-4329-bc16-9cec05203f1b',
];

export const STORAGE_SUBSCRIPTION_FORM = 'subscriptionForm';
export const TIM_COURSES_PRICE = '119,99';
export const WHATSAPP_LINK =
  'https://api.whatsapp.com/send/?phone=551940422271&text&type=phone_number&app_absent=0';

export const FREE_TRIAL_MD5_HASH = '4357c962ab78a3963f336908352c3adf';
export const PAGE_COURSES_LINK = 'https://ofertas.ampli.com.br/cursos/';
export const CURRENT_AVA_PROD_LOGIN = 'https://ava.ampli.com.br/login';
export const CURRENT_ENTRANCE_PROD_LOGIN =
  'https://www.ampli.com.br/processo-seletivo/login';

export const UNAVAILABLE_COURSE_URLS = [
  'https://www.ampli.com.br/graduacao/radiologia',
  'https://www.ampli.com.br/graduacao/estetica-e-cosmetica',
  'https://www.ampli.com.br/graduacao/ciencias-biologicas',
  'https://www.ampli.com.br/graduacao/seguranca-no-trabalho',
];

export const REDIRECT_COURSE_URLS = {
  '/graduacao/arquitetura-de-dados/':
    'graduacao/arquitetura-de-dados-tecnologo/',
  '/graduacao/blockchain-criptomoedas-e-financas-na-era-digital/':
    'graduacao/blockchain--criptomoedas-e-financas-na-era-digital-tecnologo/',
  '/graduacao/ciberseguranca/': 'graduacao/ciberseguranca-tecnologo/',
  '/graduacao/ciencia-da-computacao/': 'graduacao/ciencia-da-computacao-novo/',
  '/graduacao/ciencia-de-dados': 'graduacao/ciencia-de-dados-tecnologo/',
  '/graduacao/ciencia-politica/': 'graduacao/ciencia-politica-bacharelado/',
  '/graduacao/ciencias-contabeis/': 'graduacao/ciencias-contabeis-bacharelado/',
  '/graduacao/ciencias-economicas/':
    'graduacao/ciencias-economicas-bacharelado/',
  '/graduacao/coaching-e-desenvolvimento-humano/':
    'graduacao/coaching-e-desenvolvimento-humano-tecnologo/',
  '/graduacao/comercio-exterior/': 'graduacao/comercio-exterior-tecnologo/',
  '/graduacao/computacao-em-nuvem/': 'graduacao/computacao-em-nuvem-tecnologo/',
  '/graduacao/criminologia/': 'graduacao/criminologia-bacharelado/',
  '/graduacao/desenvolvimento-back-end/':
    'graduacao/desenvolvimento-back-end-tecnologo/',
  '/graduacao/desenvolvimento-mobile/':
    'graduacao/desenvolvimento-mobile-tecnologo/',
  '/graduacao/desenvolvimento-web': 'graduacao/desenvolvimento-web-tecnologo/',
  '/graduacao/design-de-moda/': 'graduacao/design-de-moda-tecnologo/',
  '/graduacao/design-de-moda-tecnologo/': 'graduacao/design-grafico-tecnologo/',
  '/graduacao/devops/': 'graduacao/devops-tecnologo/',
  '/graduacao/educacao-especial/': 'graduacao/educacao-especial-licenciatura/',
  '/graduacao/empreendedorismo/': 'graduacao/empreendedorismo-tecnologo/',
  '/graduacao/engenharia-civil/': 'graduacao/engenharia-civil-bacharelado/',
  '/graduacao/engenharia-da-computacao':
    'graduacao/engenharia-da-computacao-bacharelado/',
  '/graduacao/engenharia-de-controle-e-automacao/':
    'graduacao/engenharia-de-controle-e-automacao-bacharelado/',
  '/graduacao/engenharia-de-producao/':
    'graduacao/engenharia-de-producao-bacharelado/',
  '/graduacao/engenharia-de-software/':
    'graduacao/engenharia-de-software-bacharelado/',
  '/graduacao/engenharia-eletrica/':
    'graduacao/engenharia-eletrica-bacharelado',
  '/graduacao/engenharia-mecanica/':
    'graduacao/engenharia-mecanica-bacharelado/',
  '/graduacao/eventos': 'graduacao/eventos-tecnologo/',
  '/graduacao/fotografia/': 'graduacao/fotografia-tecnologo',
  '/graduacao/gastronomia/': 'graduacao/gastronomia-tecnologo/',
  '/graduacao/geografia/': 'graduacao/geografia-licenciatura/',
  '/graduacao/gerontologia/': 'graduacao/gerontologia-tecnologo/',
  '/graduacao/gestao-ambiental/': 'graduacao/gestao-ambiental-tecnologo/',
  '/graduacao/gestao-comercial/': 'graduacao/gestao-comercial-tecnologo/',
  '/graduacao/gestao-da-inovacao/': 'graduacao/gestao-da-inovacao-tecnologo/',
  '/graduacao/gestao-da-producao-industrial/':
    'graduacao/gestao-da-producao-industrial-tecnologo/',
  '/graduacao/gestao-da-qualidade/': 'graduacao/gestao-da-qualidade-tecnologo/',
  '/graduacao/gestao-da-tecnologia-da-informacao/':
    'graduacao/gestao-da-tecnologia-da-informacao-tecnologo/',
  '/graduacao/gestao-de-produto/': 'graduacao/gestao-de-produto-tecnologo/',
  '/graduacao/gestao-de-recursos-humanos/':
    'graduacao/gestao-de-recursos-humanos-tecnologo/',
  '/graduacao/gestao-de-saude-publica/':
    'graduacao/gestao-de-saude-publica-tecnologo/',
  '/graduacao/gestao-de-seguranca-privada/':
    'graduacao/gestao-de-seguranca-privada-tecnologo/',
  '/graduacao/gestao-de-turismo/': 'graduacao/gestao-de-turismo-tecnologo/',
  '/graduacao/gestao-financeira/': 'graduacao/gestao-financeira-tecnologo/',
  '/graduacao/gestao-hospitalar/': 'graduacao/gestao-hospitalar-tecnologo/',
  '/graduacao/gestao-portuaria/': 'graduacao/gestao-portuaria-tecnologo/',
  '/graduacao/gestao-publica/': 'graduacao/gestao-publica-tecnologo/',
  '/graduacao/historia/': 'graduacao/historia-licenciatura/',
  '/graduacao/inteligencia-de-mercado-e-analise-de-dados/':
    'graduacao/inteligencia-de-mercado-e-analise-de-dados-tecnologo/',
  '/graduacao/investigacao-e-pericia-criminal/':
    'graduacao/investigacao-e-pericia-criminal-tecnologo/',
  '/graduacao/jogos-digitais/': 'graduacao/jogos-digitais-tecnologo/',
  '/graduacao/jornalismo/': 'graduacao/jornalismo-bacharelado/',
  '/graduacao/letras-portugues/': 'graduacao/letras-portugues-licenciatura/',
  '/graduacao/letras-portugues-espanhol/':
    'graduacao/letras-portugues-e-espanhol-licenciatura/',
  '/graduacao/letras-portugues-ingles/':
    'graduacao/letras-portugues-e-ingles-licenciatura/',
  '/graduacao/logistica/': 'graduacao/logistica-tecnologo/',
  '/graduacao/marketing/': 'graduacao/marketing-tecnologo/',
  '/graduacao/marketing-digital/': 'graduacao/marketing-digital-tecnologo/',
  '/graduacao/matematica/': 'graduacao/matematica-licenciatura/',
  '/graduacao/mediacao/': 'graduacao/mediacao-tecnologo/',
  '/graduacao/negocios-imobiliarios/':
    'graduacao/negocios-imobiliarios-tecnologo/',
  '/graduacao/pedagogia/': 'graduacao/pedagogia-licenciatura/',
  '/graduacao/processos-gerenciais/':
    'graduacao/processos-gerenciais-tecnologo/',
  '/graduacao/publicidade-e-propaganda/':
    'graduacao/publicidade-e-propaganda-bacharelado/',
  '/graduacao/redes-de-computadores/':
    'graduacao/redes-de-computadores-tecnologo/',
  '/graduacao/relacoes-internacionais/':
    'graduacao/relacoes-internacionais-bacharelado/',
  '/graduacao/secretariado/': 'graduacao/secretariado-tecnologo/',
  '/graduacao/seguranca-publica/': 'graduacao/seguranca-publica-tecnologo/',
  '/graduacao/servicos-juridicos-cartorarios-e-notariais/':
    'graduacao/servicos-juridicos-cartorarios-e-notariais-tecnologo/',
  '/graduacao/sistemas-de-informacao/':
    'graduacao/sistemas-de-informacao-bacharelado/',
  '/graduacao/sistemas-para-internet/':
    'graduacao/sistemas-para-internet-tecnologo/',
  '/graduacao/sociologia/': 'graduacao/sociologia-licenciatura/',
  '/graduacao/teologia/': 'graduacao/teologia-bacharelado/',
  '/graduacao/terapias-integrativas-e-complementares/':
    'graduacao/terapias-integrativas-e-complementares-tecnologo/',
};
