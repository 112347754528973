import { getColor, css, px2grid } from '@ampli/ui';
const neutralTitleColor = getColor('neutral-title');
const labelFontFamily = 'Space Grotesk, sans-serif';
export const labelStyle = {
  labelWeight: 700,
  labelColor: neutralTitleColor,
  labelFontFamily: labelFontFamily
};
export const labelNormalFontStyle = {
  labelWeight: 700,
  labelColor: neutralTitleColor
};
export const specialNeedsLegend = css`
  width: 100%;
  margin-top: ${px2grid(15)};
  font-weight: 700;
  color: ${neutralTitleColor};
`;