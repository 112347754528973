import { size, sumBy, calculateProgress } from '@ampli/utils';
import { pathUrlField } from './commons';
const progress = {
  read(_, _ref) {
    let {
      readField
    } = _ref;
    const learningObjectRefs = readField('learningObjects');
    const completedFromCache = sumBy(learningObjectRefs, learningObjectRef => readField('completed', learningObjectRef));
    const {
      quantity,
      completed,
      percentage,
      percentageLabel
    } = calculateProgress(completedFromCache, size(learningObjectRefs));
    return {
      quantity,
      completed,
      percentage,
      percentageLabel
    };
  }

};
export default {
  keyFields: ['id'],
  fields: {
    pathUrl: pathUrlField,
    progress
  }
};