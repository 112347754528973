export const STORAGE_SUBSCRIPTION_FORM = 'subscriptionForm';
export const DEGREE_TYPES = {
  UNDERGRADUATE: 'Graduação',
  GRADUATE: 'Pós-graduação',
  EJA: 'EJA/Técnico' // TECHNICAL: 'Técnico',

};
export const INGRESS_MODALITY = 'IngressModality';
export const SPECIAL_FIELDS_TO_SHOW_BY_DEGREE_TYPE = {
  UNDERGRADUATE: [INGRESS_MODALITY],
  GRADUATE: [],
  EJA: [] // TECHNICAL: [],

};
export const EJACoursesIds = {
  highSchoolCourseId: '7668f270-3b88-4310-aba3-d6ee8d51c78a',
  elementarySchoolCourseId: 'f9d5b163-1dc2-4f30-8574-ee3a5d5081ba',
  ninthGradeElementarySchoolCourseId: '940fcdc5-c8e7-46a7-bdb4-58e8e5f2b273',
  thirdGradeHighSchoolCourseId: 'c5cd981a-af30-4ef0-955b-e241e807735c',
  seventhToNinthGradeElementarySchoolCourseId: '9d493243-4662-46a0-b4ee-88f6f346c1da',
  eighthToNinthGradeElementarySchoolCourseId: '55d1a2d5-e96a-4796-98a1-24ad2bc781eb',
  secondToThirdGradeHighSchoolCourseId: 'fdaa6f10-90e5-47d1-b1e9-8028c92afdb2'
};