import { Interval, DateTime, fromPairs, map } from '@ampli/utils';

const imagesUrlFromFormats = (formats, baseUrl) => fromPairs(map(formats, format => [format.split('.')[0], `${baseUrl}/${format}`]));

export const imageField = {
  read(cached, _ref) {
    let {
      readField
    } = _ref;
    if (cached) return cached;
    const imageRef = readField('image');
    const formats = readField('formats', imageRef);
    const baseUrl = readField('baseUrl', imageRef);
    return imagesUrlFromFormats(formats, baseUrl);
  }

};
export const pathUrlField = {
  read(cached, _ref2) {
    let {
      readField
    } = _ref2;
    if (cached) return cached;
    return readField('id');
  }

};
export const vodDecodedUrlField = {
  read(cached, _ref3) {
    let {
      readField
    } = _ref3;
    if (cached) return cached;
    return readField('vodUrl').replaceAll('%2F', '/');
  }

};
export const dateField = {
  read(date) {
    return date ? DateTime.fromISO(date) : null;
  }

};
export const intervalField = {
  read(cached, _ref4) {
    let {
      readField
    } = _ref4;
    if (cached) return cached;
    const startDate = readField('startDate');
    const endDate = readField('endDate');
    return startDate && endDate ? Interval.fromDateTimes(startDate, endDate) : null;
  }

};